/**
 * Liste des tables à afficher dans la vue Accueil (Home)
 *
 * Chaque objet contient :
 *          - un nom
 *          - sa route
 *          - s'il est nouveau
 *
 */
import { hasOneOfRole } from "../../service/role_service.js";
import { defines } from "../../router/defines.js";
import { EntryTypeEnum } from "../home/home-tools.js";

import * as log from "@/tools/logger.js";

export const tableList = [
  {
    name: "-PermissionDenied",
    typeRoute: EntryTypeEnum.SINGLE_ROUTE,
    routes: [defines.permission.path],
    isNew: false,
  },

  {
    name: "Tables Activités",
    typeRoute: EntryTypeEnum.SECTION_TITLE,
    routes: [
      {
        name: "Activités",
        typeRoute: EntryTypeEnum.SECTION_ROUTE,
        routes: [defines.activities.table.path],
        roles: [defines.activities.table.roles],
        isNew: false,
      },
      {
        name: "Sous-activités",
        typeRoute: EntryTypeEnum.SECTION_ROUTE,
        routes: [defines.subActivities.table.path],
        roles: [defines.subActivities.table.roles],
        isNew: false,
      },
      {
        name: "Super activités",
        typeRoute: EntryTypeEnum.SECTION_ROUTE,
        routes: [defines.superActivities.table.path],
        roles: [defines.superActivities.table.roles],
        isNew: false,
      },
      {
        name: "Templates de capacités",
        typeRoute: EntryTypeEnum.SECTION_ROUTE,
        routes: [defines.capacities.table.path],
        roles: [defines.capacities.table.roles],
        isNew: false,
      },
    ],
    isNew: false,
  },

  {
    name: "Tables Établissements",
    typeRoute: EntryTypeEnum.SECTION_TITLE,
    routes: [
      {
        name: "Établissements.DIRECTION",
        typeRoute: EntryTypeEnum.SECTION_ROUTE,
        routes: [defines.etablishments.table.path],
        roles: [defines.etablishments.table.roles],
        isNew: false,
      },
      {
        name: "Statuts établissements",
        typeRoute: EntryTypeEnum.SECTION_ROUTE,
        routes: [defines.establishmentsStatus.table.path],
        roles: [defines.establishmentsStatus.table.roles],
        isNew: false,
      },
      {
        name: "Établissements.JURIS",
        typeRoute: EntryTypeEnum.SECTION_ROUTE,
        routes: [defines.establishmentsJuris.table.path],
        roles: [defines.establishmentsJuris.table.roles],
        isNew: false,
      },
      {
        name: "Super établissements",
        typeRoute: EntryTypeEnum.SECTION_ROUTE,
        routes: [defines.superEstablishments.table.path],
        roles: [defines.superEstablishments.table.roles],
        isNew: false,
      },
      {
        name: "Référent / Fonction digitale",
        typeRoute: EntryTypeEnum.SECTION_ROUTE,
        routes: [defines.referentFunction.table.path],
        roles: [defines.referentFunction.table.roles],
        isNew: false,
      },
      {
        name: "Capacités établissements",
        typeRoute: EntryTypeEnum.SECTION_ROUTE,
        routes: [defines.establishmentCapacities.view.path],
        roles: [defines.establishmentCapacities.view.roles],
        isNew: false,
      },
    ],
    isNew: false,
  },

  {
    name: "Tables Structures juridiques",
    typeRoute: EntryTypeEnum.SECTION_TITLE,
    routes: [
      {
        name: "Structures juridiques",
        typeRoute: EntryTypeEnum.SECTION_ROUTE,
        routes: [defines.legalStructure.table.path],
        roles: [defines.legalStructure.table.roles],
        isNew: false,
      },
      {
        name: "Actionnaires, Membres et Adhérents",
        typeRoute: EntryTypeEnum.SECTION_ROUTE,
        routes: [defines.stockholders.table.path],
        roles: [defines.stockholders.table.roles],
        isNew: false,
      },
      {
        name: "Contacts contrats externes",
        typeRoute: EntryTypeEnum.SECTION_ROUTE,
        routes: [defines.externalContractContact.table.path],
        roles: [defines.externalContractContact.table.roles],
        isNew: false,
      },
      {
        name: "Nature structures juridiques",
        typeRoute: EntryTypeEnum.SECTION_ROUTE,
        routes: [defines.legalStructureTypes.table.path],
        roles: [defines.legalStructureTypes.table.roles],
        isNew: false,
      },
      {
        name: "Qualité du représentant légal",
        typeRoute: EntryTypeEnum.SECTION_ROUTE,
        routes: [defines.legalRepresentativeStatus.table.path],
        roles: [defines.legalRepresentativeStatus.table.roles],
        isNew: false,
      },
      {
        name: "Statuts structures juridiques",
        typeRoute: EntryTypeEnum.SECTION_ROUTE,
        routes: [defines.legalStructureStatus.table.path],
        roles: [defines.legalStructureStatus.table.roles],
        isNew: false,
      },
      {
        name: "Super structures juridiques",
        typeRoute: EntryTypeEnum.SECTION_ROUTE,
        routes: [defines.superLegalStructure.table.path],
        roles: [defines.superLegalStructure.table.roles],
        isNew: false,
      },
      {
        name: "Types d'évènements",
        typeRoute: EntryTypeEnum.SECTION_ROUTE,
        routes: [defines.legalStructureEventTypes.table.path],
        roles: [defines.legalStructureEventTypes.table.roles],
        isNew: false,
      },
      {
        name: "Types de contrats externes",
        typeRoute: EntryTypeEnum.SECTION_ROUTE,
        routes: [defines.externalContractTypes.table.path],
        roles: [defines.externalContractTypes.table.roles],
        isNew: false,
      },
    ],
    isNew: false,
  },

  {
    name: "Tables Ticketing",
    typeRoute: EntryTypeEnum.SECTION_TITLE,
    routes: [
      {
        name: "Destinataires ticketing",
        typeRoute: EntryTypeEnum.SECTION_ROUTE,
        routes: [defines.ticketingRecipientType.table.path],
        roles: [defines.ticketingRecipientType.table.roles],
        isNew: false,
      },
      {
        name: "Statuts ticketing par défaut",
        typeRoute: EntryTypeEnum.SECTION_ROUTE,
        routes: [defines.ticketingStatus.table.path],
        roles: [defines.ticketingStatus.table.roles],
        isNew: false,
      },
      {
        name: "Statuts ticketing spécifiques",
        typeRoute: EntryTypeEnum.SECTION_ROUTE,
        routes: [defines.ticketingSpecificStatus.table.path],
        roles: [defines.ticketingSpecificStatus.table.roles],
        isNew: false,
      },
      {
        name: "Objets / sous-objets ticketing",
        typeRoute: EntryTypeEnum.SECTION_ROUTE,
        routes: [defines.ticketingObjectSubobjects.table.path],
        roles: [defines.ticketingObjectSubobjects.table.roles],
        isNew: false,
      },
      {
        name: "Périmètre",
        typeRoute: EntryTypeEnum.SECTION_ROUTE,
        routes: [defines.ticketingPerimeter.table.path],
        roles: [defines.ticketingPerimeter.table.roles],
        isNew: false,
      },
      {
        name: "Niveaux d'urgence ticketing",
        typeRoute: EntryTypeEnum.SECTION_ROUTE,
        routes: [defines.emergencyLevelTicketing.table.path],
        roles: [defines.emergencyLevelTicketing.table.roles],
        isNew: false,
      },
    ],
    isNew: false,
  },

  {
    name: "Tables Transmissions",
    typeRoute: EntryTypeEnum.SECTION_TITLE,
    routes: [
      {
        name: "Écosystèmes",
        typeRoute: EntryTypeEnum.SECTION_ROUTE,
        routes: [defines.transmission.ecosystems.table.path],
        roles: [defines.transmission.ecosystems.table.roles],
        isNew: false,
      },
      {
        name: "Moyens de communication",
        typeRoute: EntryTypeEnum.SECTION_ROUTE,
        routes: [defines.transmission.mediums.table.path],
        roles: [defines.transmission.mediums.table.roles],
        isNew: false,
      },
      {
        name: "Niveaux d'urgence transmission",
        typeRoute: EntryTypeEnum.SECTION_ROUTE,
        routes: [defines.emergencyLevelTransmission.table.path],
        roles: [defines.emergencyLevelTransmission.table.roles],
        isNew: false,
      },
      {
        name: "Autres types de destinataires",
        typeRoute: EntryTypeEnum.SECTION_ROUTE,
        routes: [defines.transmission.otherrecipienttypes.table.path],
        roles: [defines.transmission.otherrecipienttypes.table.roles],
        isNew: false,
      },
      {
        name: "Statuts transmissions",
        typeRoute: EntryTypeEnum.SECTION_ROUTE,
        routes: [defines.transmission.status.table.path],
        roles: [defines.transmission.status.table.roles],
        isNew: false,
      },
      {
        name: "Objets / sous-objets transmission",
        typeRoute: EntryTypeEnum.SECTION_ROUTE,
        routes: [defines.transmission.transmissions.table.path],
        roles: [defines.transmission.transmissions.table.roles],
        isNew: false,
      },
      {
        name: "Périmètres",
        typeRoute: EntryTypeEnum.SECTION_ROUTE,
        routes: [defines.transmission.perimeters.table.path],
        roles: [defines.transmission.perimeters.table.roles],
        isNew: false,
      },
      {
        name: "Compositions cellules clients",
        typeRoute: EntryTypeEnum.SECTION_ROUTE,
        routes: [defines.transmission.customerCells.table.path],
        roles: [defines.transmission.customerCells.table.roles],
        isNew: false,
      },
    ],
    isNew: false,
  },

  {
    name: "Tables Produits et Tarifs",
    typeRoute: EntryTypeEnum.SECTION_TITLE,
    routes: [
      {
        name: "Produits",
        typeRoute: EntryTypeEnum.SECTION_ROUTE,
        routes: [defines.avecProducts.table.path],
        roles: [defines.avecProducts.table.roles],
        isNew: false,
      },
      {
        name: "Catégorie de produits",
        typeRoute: EntryTypeEnum.SECTION_ROUTE,
        routes: [defines.avecProductsCategory.table.path],
        roles: [defines.avecProductsCategory.table.roles],
        isNew: false,
      },
      {
        name: "Types de tarifs",
        typeRoute: EntryTypeEnum.SECTION_ROUTE,
        routes: [defines.pricesTypes.table.path],
        roles: [defines.pricesTypes.table.roles],
        isNew: false,
      },
      {
        name: "Unités de tarifs",
        typeRoute: EntryTypeEnum.SECTION_ROUTE,
        routes: [defines.pricesUnits.table.path],
        roles: [defines.pricesUnits.table.roles],
        isNew: false,
      },
      {
        name: "Tarifs par établissement",
        typeRoute: EntryTypeEnum.SECTION_ROUTE,
        routes: [defines.priceEstablishment.table.path],
        roles: [defines.priceEstablishment.table.roles],
        isNew: false,
      },
      {
        name: "Tarifs Groupe",
        typeRoute: EntryTypeEnum.SECTION_ROUTE,
        routes: [defines.priceGroup.table.path],
        roles: [defines.priceGroup.table.roles],
        isNew: false,
      },
    ],
  },

  {
    name: "Tables Gestion des clients",
    typeRoute: EntryTypeEnum.SECTION_TITLE,
    isNew: false,
    routes: [
      {
        name: "Consentements",
        typeRoute: EntryTypeEnum.SECTION_ROUTE,
        routes: [defines.consents.table.path],
        roles: [defines.consents.table.roles],
        isNew: false,
      },
    ],
  },

  {
    name: "Autres Tables",
    typeRoute: EntryTypeEnum.SECTION_TITLE,
    routes: [
      {
        name: "Acronymes",
        typeRoute: EntryTypeEnum.SECTION_ROUTE,
        routes: [defines.acronyms.table.path],
        roles: [defines.acronyms.table.roles],
        isNew: false,
      },
      {
        name: "Nature relation de travail",
        typeRoute: EntryTypeEnum.SECTION_ROUTE,
        routes: [defines.workRelationshipTypes.table.path],
        roles: [defines.workRelationshipTypes.table.roles],
        isNew: false,
      },
      {
        name: "Types de rémunération",
        typeRoute: EntryTypeEnum.SECTION_ROUTE,
        routes: [defines.remunerationTypes.table.path],
        roles: [defines.remunerationTypes.table.roles],
        isNew: false,
      },
      {
        name: "Motifs de recrutement",
        typeRoute: EntryTypeEnum.SECTION_ROUTE,
        routes: [defines.recruitingReason.table.path],
        roles: [defines.recruitingReason.table.roles],
        isNew: false,
      },
      {
        name: "Compléments accès poste",
        typeRoute: EntryTypeEnum.SECTION_ROUTE,
        routes: [defines.jobAdRequiredPoint.table.path],
        roles: [defines.jobAdRequiredPoint.table.roles],
        isNew: false,
      },
      {
        name: "Grands domaines ROME",
        typeRoute: EntryTypeEnum.SECTION_ROUTE,
        routes: [defines.largeDomainRome.table.path],
        roles: [defines.largeDomainRome.table.roles],
        isNew: false,
      },
      {
        name: "Intitulés poste",
        typeRoute: EntryTypeEnum.SECTION_ROUTE,
        routes: [defines.jobTitle.table.path],
        roles: [defines.jobTitle.table.roles],
        isNew: false,
      },
      {
        name: "Zones de mobilité",
        typeRoute: EntryTypeEnum.SECTION_ROUTE,
        routes: [defines.mobilityzones.table.path],
        roles: [defines.mobilityzones.table.roles],
        isNew: false,
      },
      {
        name: "Avantages groupe",
        typeRoute: EntryTypeEnum.SECTION_ROUTE,
        routes: [defines.advantages.table.path],
        roles: [defines.advantages.table.roles],
        isNew: false,
      },
      {
        name: "Temps de travail",
        typeRoute: EntryTypeEnum.SECTION_ROUTE,
        routes: [defines.worksDuration.table.path],
        roles: [defines.worksDuration.table.roles],
        isNew: false,
      },
      {
        name: "Titres collaborateurs",
        typeRoute: EntryTypeEnum.SECTION_ROUTE,
        routes: [defines.employeetitles.table.path],
        roles: [defines.employeetitles.table.roles],
        isNew: false,
      },
      {
        name: "Types de collaborateurs",
        typeRoute: EntryTypeEnum.SECTION_ROUTE,
        routes: [defines.usertypes.table.path],
        roles: [defines.usertypes.table.roles],
        isNew: false,
      },
      {
        name: "Rubriques globales",
        typeRoute: EntryTypeEnum.SECTION_ROUTE,
        routes: [defines.globalRubrics.table.path],
        roles: [defines.globalRubrics.table.roles],
        isNew: false,
      },
      {
        name: "Services",
        typeRoute: EntryTypeEnum.SECTION_ROUTE,
        routes: [defines.services.table.path],
        roles: [defines.services.table.roles],
        isNew: false,
      },
      {
        name: "Sexes",
        typeRoute: EntryTypeEnum.SECTION_ROUTE,
        routes: [defines.genders.table.path],
        roles: [defines.genders.table.roles],
        isNew: false,
      },
      {
        name: "Spécialités médicales",
        typeRoute: EntryTypeEnum.SECTION_ROUTE,
        routes: [defines.medicalSpecialties.table.path],
        roles: [defines.medicalSpecialties.table.roles],
        isNew: false,
      },
      {
        name: "Applications",
        typeRoute: EntryTypeEnum.SECTION_ROUTE,
        routes: [defines.applications.table.path],
        roles: [defines.applications.table.roles],
        isNew: false,
      },
      {
        name: "Civilités",
        typeRoute: EntryTypeEnum.SECTION_ROUTE,
        routes: [defines.civilities.table.path],
        roles: [defines.civilities.table.roles],
        isNew: false,
      },
      {
        name: "Contextes note de frais",
        typeRoute: EntryTypeEnum.SECTION_ROUTE,
        routes: [defines.expenseReportContext.table.path],
        roles: [defines.expenseReportContext.table.roles],
        isNew: false,
      },
      {
        name: "Conventions collectives",
        typeRoute: EntryTypeEnum.SECTION_ROUTE,
        routes: [defines.collectiveAgreement.table.path],
        roles: [defines.collectiveAgreement.table.roles],
        isNew: false,
      },
      {
        name: "Fonctions digitales",
        typeRoute: EntryTypeEnum.SECTION_ROUTE,
        routes: [defines.functions.table.path],
        roles: [defines.functions.table.roles],
        isNew: false,
      },
      {
        name: "Marques métier / nom de domaine",
        typeRoute: EntryTypeEnum.SECTION_ROUTE,
        routes: [defines.domainBrands.table.path],
        roles: [defines.domainBrands.table.roles],
        isNew: false,
      },
      {
        name: "Métiers",
        typeRoute: EntryTypeEnum.SECTION_ROUTE,
        routes: [defines.metiers.table.path],
        roles: [defines.metiers.table.roles],
        isNew: false,
      },
      {
        name: "Motifs CDD",
        typeRoute: EntryTypeEnum.SECTION_ROUTE,
        routes: [defines.cddreasons.table.path],
        roles: [defines.cddreasons.table.roles],
        isNew: false,
      },
      {
        name: "Motifs congés",
        typeRoute: EntryTypeEnum.SECTION_ROUTE,
        routes: [defines.vacationreasons.table.path],
        roles: [defines.vacationreasons.table.roles],
        isNew: false,
      },
      {
        name: "Motifs maladie",
        typeRoute: EntryTypeEnum.SECTION_ROUTE,
        routes: [defines.diseasesReason.table.path],
        roles: [defines.diseasesReason.table.roles],
        isNew: false,
      },
      {
        name: "Motifs temps de travail <24h",
        typeRoute: EntryTypeEnum.SECTION_ROUTE,
        routes: [defines.worksDurationReason.table.path],
        roles: [defines.worksDurationReason.table.roles],
        isNew: false,
      },
      {
        name: "Natures remboursement",
        typeRoute: EntryTypeEnum.SECTION_ROUTE,
        routes: [defines.refundTypes.table.path],
        roles: [defines.refundTypes.table.roles],
        isNew: false,
      },
      {
        name: "Organisations temps de travail",
        typeRoute: EntryTypeEnum.SECTION_ROUTE,
        routes: [defines.workingTimeOrganization.table.path],
        roles: [defines.workingTimeOrganization.table.roles],
        isNew: false,
      },
      {
        name: "Origines prospect",
        typeRoute: EntryTypeEnum.SECTION_ROUTE,
        routes: [defines.prospectOrigins.table.path],
        roles: [defines.prospectOrigins.table.roles],
        isNew: false,
      },
      {
        name: "Rubriques",
        typeRoute: EntryTypeEnum.SECTION_ROUTE,
        routes: [defines.rubrics.table.path],
        roles: [defines.rubrics.table.roles],
        isNew: false,
      },
      {
        name: "Primes",
        typeRoute: EntryTypeEnum.SECTION_ROUTE,
        routes: [defines.primes.table.path],
        roles: [defines.primes.table.roles],
        isNew: false,
      },
      {
        name: "Contextes des rubriques",
        typeRoute: EntryTypeEnum.SECTION_ROUTE,
        routes: [defines.rubricsContext.table.path],
        roles: [defines.rubricsContext.table.roles],
        isNew: false,
      },
      {
        name: "Statuts présence pour le travail",
        typeRoute: EntryTypeEnum.SECTION_ROUTE,
        routes: [defines.fitForWorkStatus.table.path],
        roles: [defines.fitForWorkStatus.table.roles],
        isNew: false,
      },
      {
        name: "Faqs",
        typeRoute: EntryTypeEnum.SECTION_ROUTE,
        routes: [defines.faqs.table.path],
        roles: [defines.faqs.table.roles],
        isNew: false,
      },
    ],
    isNew: false,
  },
];

/** Retourne la liste des tables dans l'ordre craoissant alphabétique */
export function getSortAscendantTable() {
  let table = [];
  log.debug("get sort table for home page");

  // Parcours de toutes les tables et vérification du matching de rôles
  // puis ajout à la liste home des tables autorisées
  for (let item of tableList) {
    // table ou section ?
    if (item.typeRoute == EntryTypeEnum.SINGLE_ROUTE) {
      // c'est une table
      if (hasOneOfRole(item.roles)) {
        table.push(item);
      }
    } else if (item.typeRoute == EntryTypeEnum.SECTION_TITLE) {
      // c'est une section
      let section = [];

      // Parcours des item dans la section
      for (let itemSection of item.routes) {
        // l'item de la section est-il authorisé
        if (hasOneOfRole(itemSection.roles)) {
          section.push(itemSection);
        }
      }

      // La section n'est pas vide :
      // on réinitialise la liste des routes de la section
      // on ajoute  les routes authorisée à la section
      if (section.length > 0) {
        let addSection = JSON.parse(JSON.stringify(item));
        addSection.routes = [];
        addSection.routes = section;
        table.push(addSection);
      }
    }
  }

  return table.sort(function (a, b) {
    if (a.typeRoute != EntryTypeEnum.SECTION_TITLE)
      return a.name.localeCompare(b.name);
  });
}

/** Retourne la liste des tables*/
export function getTable() {
  return tableList;
}
