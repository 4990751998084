var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.title
      ? _c("div", { staticClass: "d-flex justify-center text-h6" }, [
          _vm._v(_vm._s(_vm.title)),
        ])
      : _vm._e(),
    _c("canvas", {
      attrs: { id: "canvas", width: _vm.width, height: _vm.height },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }