var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._t("default", function () {
        return [
          _c(
            "v-card",
            { staticClass: "mr-2", attrs: { tile: "", elevation: "0" } },
            [
              _vm.showTitle
                ? _c(
                    "div",
                    {
                      staticClass:
                        "d-flex my-1 py-1 grey lighten-3 pl-3 medium",
                    },
                    [_vm._v(" " + _vm._s(_vm.getSectionName()) + " ")]
                  )
                : _vm._e(),
              _vm._l(_vm.routes, function (table, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "pl-3" },
                  [
                    _c(
                      "v-card",
                      {
                        staticClass: "ml-6",
                        attrs: {
                          tile: "",
                          elevation: "0",
                          to: table.routes[0],
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "d-flex my-1" },
                          [
                            _vm._v(" " + _vm._s(table.name) + " "),
                            _vm.isNew
                              ? _c(
                                  "v-icon",
                                  { staticClass: "ml-3 justify-center" },
                                  [_vm._v(" $vuetify.icons.iconNew ")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm.divider ? _c("v-divider") : _vm._e(),
                  ],
                  1
                )
              }),
            ],
            2
          ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }