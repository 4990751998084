<template>
  <div>
    <div class="d-flex justify-center text-h6" v-if="title">{{ title }}</div>
    <!-- style="border:1px solid #d3d3d3 " -->
    <canvas id="canvas" :width="width" :height="height"></canvas>
  </div>
</template>

<script>
export default {
  props: {
    /**la largeur du canvas */
    width: {
      type: String,
      default: "800",
    },
    /**la hauteur du canvas */
    height: {
      type: String,
      default: "400",
    },
    /**l'étape courant du workflow */
    currentStep: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: undefined,
    },
    /**les étapes */
    steps: {
      type: Array,
      default: function () {
        return [
          {
            id: 0,
            centerX: 0,
            centerY: 0,
            label: "première étape",
          },
        ];
      },
    },
    labelWidth: {
      type: Number,
      default: 200,
    },
    /** la largeur de la ligne de séparation */
    lineWidth: {
      type: Number,
      default: 200,
    },
  },
  data() {
    return {
      context: null,

      /**la couleur du circle pour l'étape en cours */
      circleCurrentColor: "#1E1D1D",

      /**la couleur du circle pour l'étape en cours */
      circleOtherColor: "white",

      /**la couleur du circle pour l'étape en cours */
      circleOtherStrokeColor: "#E20080",

      /** le diamètre du cercle */
      stepCircleRadius: 25,

      /**La taille de la fonte pour l'étape en cours */
      stepIdFontSize: 20,

      /** la hauteur de la ligne de séparation */
      lineHeight: 4,

      /**la couleur de la ligne */
      lineColor: "#E20080",

      /**La taille de la fonte pour l'étape en cours */
      labelFontSize: 12,
    };
  },
  methods: {
    draw() {
      var canvas = document.getElementById("canvas");

      var ctx = this.context;

      ctx.clearRect(0, 0, canvas.width, canvas.height);

      var x = 0;

      var radius = this.stepCircleRadius;

      //on calcule la largeur totale des éléments à afficher pour pouvoir les centrer
      let w =
        this.steps.length * this.stepCircleRadius * 2 +
        (this.steps.length - 1) * this.lineWidth;

      x = (Number.parseInt(this.width) - w) / 2;

      let current = 0;

      let dy = 20;

      //pour chaque étape
      this.steps.forEach((step) => {
        //on  calcule le centre du cercle
        step.centerX = x + this.stepCircleRadius;
        step.centerY = this.stepCircleRadius + 5 + dy;

        //on dessine le cercle de l'étape"
        ctx.beginPath();

        ctx.arc(step.centerX, step.centerY, radius, 0, 2 * Math.PI, false);

        let isCurrentStep = step.id === this.currentStep;

        if (isCurrentStep) {
          ctx.fillStyle = this.circleCurrentColor;

          ctx.lineWidth = this.lineHeight * 2;
          ctx.strokeStyle = this.circleOtherStrokeColor;
          ctx.stroke();
        } else {
          ctx.fillStyle = this.circleOtherColor;

          ctx.lineWidth = this.lineHeight * 2;
          ctx.strokeStyle = this.circleOtherStrokeColor;
          ctx.stroke();
        }

        ctx.fill();

        //on affiche le numéro de l'étape"
        ctx.beginPath();
        // ctx.font = `${this.stepIdFontSize}pt Roboto`;
        ctx.font = `${this.stepIdFontSize}pt Red Hat Text`;

        if (isCurrentStep) {
          ctx.fillStyle = "white";
        } else {
          ctx.fillStyle = this.circleCurrentColor;
        }

        ctx.textAlign = "center";

        ctx.fillText(step.id + 1, step.centerX, step.centerY + 9);

        //la ligne de séparation
        if (current !== this.steps.length - 1) {
          ctx.beginPath();
          ctx.fillStyle = this.lineColor;
          ctx.fillRect(
            step.centerX + radius + this.lineHeight,
            radius + dy,
            this.lineWidth,
            this.lineHeight
          );
        }

        //on affiche le texte de l'étape
        ctx.beginPath();
        //ctx.font = `${this.labelFontSize}pt Roboto`;

        ctx.fillStyle = "black";

        ctx.textAlign = "center";

        this.wrapText(
          ctx,
          step.label,
          step.centerX,
          step.centerY + radius + 40,
          this.labelWidth,
          this.stepIdFontSize + 5
        );

        //on calcule la position de la prochaine étape
        x += this.stepCircleRadius * 2 + this.lineWidth;

        //on pase à l'étape suivante
        current++;
      });
    },
    wrapText(context, text, x, y, maxWidth, lineHeight) {
      var words = text.split(" ");
      var line = "";

      for (var n = 0; n < words.length; n++) {
        var testLine = line + words[n] + " ";
        var metrics = context.measureText(testLine);
        var testWidth = metrics.width;
        if (testWidth > maxWidth && n > 0) {
          context.font = `${this.labelFontSize}pt Red Hat Text`;
          context.fillText(line, x, y);
          line = words[n] + " ";
          y += lineHeight;
        } else {
          line = testLine;
        }
      }

      context.font = `${this.labelFontSize}pt Red Hat Text`;
      context.fillText(line, x, y);
    },
  },
  mounted() {
    var canvas = document.getElementById("canvas");
    var ctx = canvas.getContext("2d");
    this.context = ctx;

    this.draw();

  },
  /**on vérifie les changements sur les variables */
  watch: {
    /**les étapes changent : on les affiche */
    steps() {
      this.draw();
    },
    currentStep() {
      this.draw();
    },
  },
};
</script>

<style>
</style>