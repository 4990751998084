/**
 * Un mixin outil pour la manipulation du workflow 'images'
 */
export let WorkflowMixin = {
  data() {
    return {
      /**les étapes du workflow */
      workflowSteps: [],

      /**L'index courant du workflow */
      workflowIndex: 0,
    };
  },
  methods: {
    /**Ajouter une étape au workflow */
    addStepForWorkflow(label) {
      let id = this.workflowSteps.length;

      let step = {
        id,
        centerX: 0,
        centerY: 0,
        label,
      };

      this.workflowSteps.push(step);
    },
    /**Passer à l'étape suivante du wokflow */
    nextStepForWorkflow() {
      let id = this.workflowIndex + 1;
      if (id < this.workflowSteps.length) {
        this.workflowIndex = id;
      }
    },
    /**Passer à précédente suivante du wokflow */
    previousStepForWorkflow() {
      let id = this.workflowIndex - 1;
      if (id >= 0) {
        this.workflowIndex = id;
      }
    },
    /**Aller à une étape du workflow */
    goToStepForWorkflow(index) {
      if (index < 0) {
        return;
      }
      if (index >= this.workflowSteps.length) {
        return;
      }

      this.workflowIndex = index;
    },
    /**Aller à la première étape du workflow */
    firstStepForWorkflow() {
      this.workflowIndex = 0;
    },
  },
  mounted() {
    //this.workflowSteps = [];
  },
  computed: {
    /**Retourne la largeur de l'image en fonction de la largeur de l'écran */
    getWidthForWorkflow() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "350px";
        case "sm":
          return "350px";
        case "md":
          return "600px";
        case "lg":
          return "600px";
        case "xl":
          return "600px";
      }
    },
  },
};

export default WorkflowMixin;
