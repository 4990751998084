<template>
  <v-container fluid class="mb-6">
    <div class="d-flex justify-center" flat tile>
      <Workflow
        width="600"
        height="180"
        :steps="workflowSteps"
        :currentStep="workflowIndex"
        :labelWidth="200"
        :lineWidth="140"
        title=""
      ></Workflow>
    </div>

    <!-- Titre de l'ihm -->
    <v-row class="mb-12" justify="center">
      <!-- <h1>Liste des tables de la Database Globale</h1> -->
      <div class="main-title">Liste des Tables de la Database Globale</div>
    </v-row>

    <!-- Ligne pour la présentaion de la liste -->
    <v-row justify="center" no-gutters>
      <v-col xl="9" lg="10" md="12" sm="12" xs="12">
        <v-row justify="center" no-gutters>
          <!-- Tourner un icone de X degrés : ajouter 'mdi-rotate-'X'' juste après le nom de l'icone     EX : mdi-table-large-plus mdi-rotate-180 -->

          <!-- First column -->
          <v-col v-show="widthScreen">
            <div v-for="(table, index) in firstColumnList" :key="index">
              <SingleRouteComponent
                v-if="displayAsSingleRoute(table)"
                :name="table.name"
                :route="table.routes[0]"
                :isNew="table.isNew"
                :divider="table.divider"
              ></SingleRouteComponent>

              <SectionTitleComponent
                v-else-if="displayAsSectionTitle(table)"
                :name="table.name"
                :divider="table.divider"
              ></SectionTitleComponent>

              <SectionRouteComponent
                v-else-if="displayAsSectionRoute(table)"
                :name="table.name"
                :route="table.routes[0]"
                :isNew="table.isNew"
                :divider="table.divider"
              ></SectionRouteComponent>
            </div>
          </v-col>

          <!-- Barre verticale de séparation entre colonne 1 et 2 -->
          <v-divider vertical class="mx-4" v-show="showSecondColumn">
          </v-divider>

          <!-- Second column -->
          <v-col v-show="showSecondColumn">
            <div v-for="(table, index) in secondColumnList" :key="index + 1000">
              <SingleRouteComponent
                v-if="displayAsSingleRoute(table)"
                :name="table.name"
                :route="table.routes[0]"
                :isNew="table.isNew"
                :divider="table.divider"
              ></SingleRouteComponent>

              <SectionTitleComponent
                v-else-if="displayAsSectionTitle(table)"
                :name="table.name"
                :divider="table.divider"
              ></SectionTitleComponent>

              <SectionRouteComponent
                v-else-if="displayAsSectionRoute(table)"
                :name="table.name"
                :route="table.routes[0]"
                :isNew="table.isNew"
                :divider="table.divider"
              ></SectionRouteComponent>
            </div>
          </v-col>

          <!-- Barre verticale de séparation entre colonne 2 et 3 -->
          <v-divider vertical class="mx-4" v-show="showThirdColumn">
          </v-divider>

          <!-- Troisième colonne -->
          <v-col v-show="showThirdColumn">
            <div v-for="(table, index) in thirdColumnList" :key="index + 10000">
              <SingleRouteComponent
                v-if="displayAsSingleRoute(table)"
                :name="table.name"
                :route="table.routes[0]"
                :isNew="table.isNew"
                :divider="table.divider"
              ></SingleRouteComponent>

              <SectionTitleComponent
                v-else-if="displayAsSectionTitle(table)"
                :name="table.name"
                :divider="table.divider"
              ></SectionTitleComponent>

              <SectionRouteComponent
                v-else-if="displayAsSectionRoute(table)"
                :name="table.name"
                :route="table.routes[0]"
                :isNew="table.isNew"
                :divider="table.divider"
              ></SectionRouteComponent>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import * as log from "@/tools/logger.js";
import Workflow from "@/components/Workflow.vue";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";
import { roleCanEdit } from "@/service/role_service.js";
import * as tableList from "@/views/home/tableList.js";

import SingleRouteComponent from "./SingleRouteComponent.vue";
import SectionTitleComponent from "./SectionTitleComponent.vue";
import SectionRouteComponent from "./SectionRouteComponent.vue";

import { buildColumns, EntryTypeEnum } from "./home-tools.js";

export default {
  components: {
    Workflow,
    SingleRouteComponent,
    SectionTitleComponent,
    SectionRouteComponent,
  },
  mixins: [WorkflowMixin],

  mounted() {
    log.debug("Mounted Home");
    //est-ce que l'utilisateur peut éditer les données
    this.canEdit = roleCanEdit();
    // Initialisation du worflow
    this.initSteps();

    // this.listOfTables = tableList.getTable();
    this.listOfTables = tableList.getSortAscendantTable();
  },

  data() {
    return {
      // Rôle d'édition ?
      canEdit: false,

      // Liste des tables à afficher
      listOfTables: [],

      // Tableau pour les items table
      firstColumnList: [],
      secondColumnList: [],
      thirdColumnList: [],

      // Affichage des colonnes 2 et 3
      showSecondColumn: false,
      showThirdColumn: false,
    };
  },

  computed: {
    // Méthode de surveillance de la taille de l'écran
    widthScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          this.buildListForOneColumn();
          break;
        case "sm":
          this.buildListForTwoColumns();
          break;
        case "md":
          this.buildListForThreeColumns();
          break;
        case "lg":
          this.buildListForThreeColumns();
          break;
        case "xl":
          this.buildListForThreeColumns();
          break;
      }
      return true;
    },
  },

  methods: {
    // Initialisation des étapes du workflow en fonction des droits de l'utilisateur
    initSteps() {
      this.addStepForWorkflow("Choisir une table");
      if (this.canEdit) {
        this.addStepForWorkflow("Consulter/Modifier la table");
      } else {
        this.addStepForWorkflow("Consulter la table");
      }
    },

    buildListForThreeColumns() {
      this.resetData();
      this.showSecondColumn = true;
      this.showThirdColumn = true;

      if (this.listOfTables.length > 0) {
        let columns = buildColumns(
          JSON.parse(JSON.stringify(this.listOfTables)),
          3
        );

        this.addDivider(columns);

        this.firstColumnList.push(...columns[0]);
        this.secondColumnList.push(...columns[1]);
        this.thirdColumnList.push(...columns[2]);
      } else {
        this.firstColumnList = [];
        this.secondColumnList = [];
        this.thirdColumnList = [];
      }
    },

    buildListForTwoColumns() {
      this.resetData();
      this.showSecondColumn = true;
      this.showThirdColumn = false;

      if (this.listOfTables.length > 0) {
        let columns = buildColumns(
          JSON.parse(JSON.stringify(this.listOfTables)),
          2
        );

        this.addDivider(columns);

        this.firstColumnList.push(...columns[0]);
        this.secondColumnList.push(...columns[1]);
      } else {
        this.firstColumnList = [];
        this.secondColumnList = [];
        this.thirdColumnList = [];
      }
    },

    buildListForOneColumn() {
      this.resetData();
      this.showSecondColumn = false;
      this.showThirdColumn = false;

      if (this.listOfTables.length > 0) {
        let columns = buildColumns(
          JSON.parse(JSON.stringify(this.listOfTables)),
          1
        );

        this.addDivider(columns);

        this.firstColumnList.push(...columns[0]);
      } else {
        this.firstColumnList = [];
        this.secondColumnList = [];
        this.thirdColumnList = [];
      }
    },

    /** Réinitialise les données d'affichage */
    resetData() {
      this.showSecondColumn = false;
      this.showThirdColumn = false;

      this.firstColumnList = [];
      this.secondColumnList = [];
      this.thirdColumnList = [];
    },

    /** Retourne le tiers de la taille de la liste des tables */
    endIndexFirstColumn() {
      return this.listOfTables.length / 3;
    },

    /** Retourne le tiers de la taille de la liste des tables */
    firstIndexSecondColumn() {
      return this.endIndexFirstColumn();
    },

    /** Retourne le second tiers de la taille de la liste des tables */
    endIndexSecondColumn() {
      return (this.listOfTables.length / 3) * 2;
    },

    /** Retourne le second tiers de la taille de la liste de tables */
    firstIndexThirdColumn() {
      return this.endIndexSecondColumn();
    },

    /** Est-ce que on affiche une route directement? */
    displayAsSingleRoute(entry) {
      if (!entry) return false;

      return entry.type === EntryTypeEnum.SINGLE_ROUTE;
    },

    /** Est-ce que on affiche un titre de section? */
    displayAsSectionTitle(entry) {
      if (!entry) return false;

      return entry.type === EntryTypeEnum.SECTION_TITLE;
    },

    /** Est-ce que on affiche une route de section? */
    displayAsSectionRoute(entry) {
      if (!entry) return false;

      return entry.type === EntryTypeEnum.SECTION_ROUTE;
    },

    /**On ajoute un divider sauf si un début de section arrive à la suite */
    addDivider(columns) {
      for (let column of columns) {
        for (let i = 0; i < column.length - 1; i++) {
          let next = i + 1;

          let entry = column[i];
          entry.divider = column[next].type !== EntryTypeEnum.SECTION_TITLE;
        }
      }
    },
  },
};
</script>
