<template>
  <div>
    <slot>
      <v-card tile elevation="0" class="mr-2">
        <div
          v-if="showTitle"
          class="d-flex my-1 py-1 grey lighten-3 pl-3 medium"
        >
          {{getSectionName()}}          
        </div>

        <div v-for="(table, index) in routes" :key="index" class="pl-3">
          <v-card tile elevation="0" :to="table.routes[0]" class="ml-6">
            <div class="d-flex my-1">              
              {{ table.name }}
            
              <v-icon
              class="ml-3 justify-center"
              v-if="isNew">
                $vuetify.icons.iconNew
              </v-icon>
              
              <!-- <img
                src="../../assets/iconNew.png"
                justify-center
                class="ml-3 justify-center"
                height="20"
                width="20"
                v-if="table.isNew"
              /> -->
            </div>
          </v-card>
          <v-divider v-if="divider"></v-divider>
        </div>
      </v-card>
    </slot>
  </div>
</template>

<script>
/**Composant permettant d'afficher une section d'élément de la page d'accueil. Ces éléments sont en général
 * regroupés par thème : ex les établisements.
 */
export default {
  name: "SectionTitleComponent",
  props: {
    /**le nom de la table */
    name: {
      type: String,
      default: "-",
    },
    routes: {
      type: Array,
      default: function () {
        return [];
      },
    },
    /**Afficher le titre de la section */
    showTitle: {
      type: Boolean,
      default: true,
    },
    divider : {
      type : Boolean,
      default : true
    }
  },
  methods: {
    getSectionName() {
      // return 'Section ' + this.name;
      return this.name;
    }
  },
};
</script>

<style>
</style>