<template>
  <div>
    <slot>
      <v-card tile elevation="0" :to="route" class="mr-2">
        <div
          class="d-flex my-1  pl-3"
        >
          {{ name }}
         
          <v-icon
          class="ml-3 justify-center"
          v-if="isNew">
           $vuetify.icons.iconNew
          </v-icon>
         
          <!-- <img
            src="../../assets/iconNew.png"
            justify-center
            class="ml-3 justify-center"
            height="20"
            width="20"
            v-if="isNew"
          /> -->
        </div>
        <div class="pl-3" v-if="divider">
          <v-divider></v-divider>
        </div>
      </v-card>
    </slot>
  </div>
</template>

<script>
/**Composant permettant d'afficher un élément de la page d'accueil. */
export default {
  name: "SingleRouteComponent",
  props: {
    /**le nom de la table */
    name: {
      type: String,
      default: "-",
    },
    /**Est-ce que c'est un nouvel élément? (affichage d'une icône en général) */
    isNew: {
      type: Boolean,
      default: false,
    },
    /**la route à atteindre suite au clic sur cet élément */
    route: {
      type: String,
      default: null,
    },
    divider : {
      type : Boolean,
      default : true
    }
  },
};
</script>

<style>
</style>