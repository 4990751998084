<template>
  <div>
    <slot>
      <v-card tile elevation="0" :to="route" class="ml-6 pl-3">
        <div class="d-flex my-1">
          {{ name }}
          
          <v-icon
          class="ml-3 justify-center"
          v-if="isNew">
           $vuetify.icons.iconNew
          </v-icon>
          
          <!-- <img
            src="../../assets/iconNew.png"
            justify-center
            class="ml-3 justify-center"
            height="20"
            width="20"
            v-if="isNew"
          /> -->
        </div>
      </v-card>
      <v-divider v-if="divider"></v-divider>
    </slot>
  </div>
</template>

<script>
/**Composant permettant d'afficher une section d'élément de la page d'accueil. Ces éléments sont en général
 * regroupés par thème : ex les établisements.
 */
export default {
  name: "SectionRouteComponent",
  props: {
    /**le nom de la table */
    name: {
      type: String,
      default: "-",
    },
    /**Est-ce que c'est un nouvel élément? (affichage d'une icône en général) */
    isNew: {
      type: Boolean,
      default: false,
    },
    /**la route à atteindre suite au clic sur cet élément */
    route: {
      type: String,
      default: null,
    },
    divider : {
      type : Boolean,
      default : true
    }
  },
};
</script>

<style>
</style>