var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._t("default", function () {
        return [
          _c(
            "v-card",
            {
              staticClass: "ml-6 pl-3",
              attrs: { tile: "", elevation: "0", to: _vm.route },
            },
            [
              _c(
                "div",
                { staticClass: "d-flex my-1" },
                [
                  _vm._v(" " + _vm._s(_vm.name) + " "),
                  _vm.isNew
                    ? _c("v-icon", { staticClass: "ml-3 justify-center" }, [
                        _vm._v(" $vuetify.icons.iconNew "),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
          _vm.divider ? _c("v-divider") : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }