//
// Outil de génération des lignes/données à afficher sur l'écran d'accueil suite à la refonte
// de ce dernier en janvier 2021. L'affichage se fait maintenant en utilisant un système de sections
// afin de regrouper les routes en fonction de thème/domaine en commun.
//

export const EntryTypeEnum = {
  SINGLE_ROUTE: "A", //une route directe (sans section)
  SECTION_TITLE: "B", //un titre de section
  SECTION_ROUTE: "C", //une route dans une section
};

/**Construire les colonnes à afficher sur la page d'acceuil. Voir le fichier tableList.js pour examiner
 * le format des données en entrée.
 * Le traitement va produire une ligne par élément à afficher :route, titre de section, route de section.
 */
export function buildColumns(data, nbColumns) {

  //on récupère le nombre d'éléments (sans les sections)
  let nbRoutes = data.reduce((sum, entry) => sum + entry.routes.length, 0);

  //on ajoute le nombre de sections
  let nbSections = data.reduce(
    (sum, entry) => sum + (entry.typeRoute == EntryTypeEnum.SECTION_TITLE ? 1 : 0),
    0
  );

  let count = nbRoutes + nbSections;

  //on calcule le nombre de lignes théorique par colonne
  let lines = Math.ceil(count / nbColumns);
  //   let slots = lines * nbColumns; //le nombre d'emplacements possibles

  //on construit la matrice des lignes
  let columns = buildMatrix(data, nbColumns, lines);

  // dump(columns, lines);

  //on regarde si un début de section se retrouve en base d'une colonne
  if (sectionOnBottom(columns, lines)) {
    //dans ce cas, on ajoute une ligne pour éviter cela.
    lines++;
    // console.log("L2:" + lines);
    columns = buildMatrix(data, nbColumns, lines);

    // dump(columns, lines);
  }

  return columns;
}

/**Construire la matrice des colonnes en fonction d'un nombre de lignes désiré */
function buildMatrix(data, nbColumns, lines) {
  let colums = [];

  // Ajout de tableaux vide au nombre de colonnes de la vue
  for (let index = 0; index < nbColumns; index++) {
    colums.push([]);
  }

  let columnIndex = 0;
  let column = colums[columnIndex]; //on part sur la première colonne

  //fonction de changement de colonne
  let changeColumnIfNeeded = () => {
    if (column.length >= lines) {
      columnIndex++; //on change de colonne
      column = colums[columnIndex];
    }
  };


  //pour chaque item en entrée (route/section)
  for (let item of data) {
    //on détermine si l'item est une section
    let isSection = item.typeRoute == EntryTypeEnum.SECTION_TITLE ? true : false;

    if (!isSection) {
      let entry = {
        ...item,
        type: EntryTypeEnum.SINGLE_ROUTE,
      };

      column.push(entry);

      changeColumnIfNeeded();
    } else {
      let entry = {
        name: item.name,
        type: EntryTypeEnum.SECTION_TITLE,
      };

      column.push(entry);
      changeColumnIfNeeded();

      //on tri les routes par ordre alphabétique
      let routes = item.routes.sort(function(a, b) {
        return a.name.localeCompare(b.name);
      });

      for (let route of routes) {
        let entry = {
          ...route,
          type: EntryTypeEnum.SECTION_ROUTE,
        };
        column.push(entry);
        changeColumnIfNeeded();
      }
    }
  }

  return colums;
}

/**Retourne true si le dernier élément d'une des colonnes est un début de section (type : SECTION_TITLE) */
function sectionOnBottom(columns, lines) {
  for (let column of columns) {
    let entry = column[lines - 1];
    if (entry && entry.type === EntryTypeEnum.SECTION_TITLE) {
      return true;
    }
  }

  return false; //aucun titre de section en base de colonne
}


